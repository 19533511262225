if (!Object.keys) {
    Object.keys = function (o) {
        "use strict";
        var k = [];
        if (o !== Object.create(o)) {
            return k;
        }
        
        var p;
        for (p in o) {
            if (Object.prototype.hasOwnProperty.call(o, p)) { k.push(p); }
        }
        return k;
    };
}